import React, { useEffect, useState } from 'react';
import BasicBars from '../../Common/Graphs/BarChart';
import Skeleton from 'react-loading-skeleton';

function Results() {
  const [graphData, setGraphData] = useState([]);
  const id = "e68c9440-af4b-49ca-9147-dbcb7d759023";
  const [loading, setLoading] = useState(true);

  const fetchData = () => {
    fetch(`https://hstonline.tech/hstsms/api/v1/barGraphFE/${id}`)
      .then(response => response.json())
      .then(data => {
        console.log('Fetched data:', data);
        setGraphData(data.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  };

  useEffect(() => {
    fetchData(); // Initial fetch

    // Set up interval to fetch data every 30 seconds
    const intervalId = setInterval(() => {
      fetchData();
    }, 10000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [id]);

  console.log('graphData:', graphData);

  return (
    <div className='flex flex-col m-auto page text-center'>
      <div className="flex flex-col text-gray-500 py-3">
        <b>LIVE VOTING BAR GRAPH</b>
      </div>

      {loading ? (
        <Skeleton count={1} height={400} duration={1} />
      ) : (
        <BasicBars x={graphData.codes} y={graphData.votes} z={graphData.barColors} />
      )}
    </div>
  );
}

export default Results;
